import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
function Header() {

    const [isActive, setIsActive] = useState(false);
    const handleClick = () => {
        setIsActive(!isActive);
    };

  return (
    <>
        <div className="collapse navbar-collapse custom-navmenu" id="main-navbar">
            <div className="container py-2 py-md-5">
            <div className="row align-items-start">
                <div className="col-md-2">
                <ul className="custom-menu">
                    <li className="active"><NavLink to="/">Home</NavLink></li>
                    <li><a href="/#services-sec">Services</a></li>
                    {/* <li><a href="about.html">About Me</a></li>
                    <li><a href="services.html">Services</a></li>
                    <li><a href="works.html">Works</a></li>
                    <li><a href="contact.html">Contact</a></li> */}
                </ul>
                </div>
                <div className="col-md-6 d-none d-md-block  mr-auto contact-details">
                    <h2 className='main-heading'>Contact</h2>
                    <p className='desc'>If you have any inquiries, kindly do not hesitate to get in touch with me via email or phone.</p>
                    <ul className="list-unstyled ">
                        <li className="mb-3">
                            <strong className="d-block mb-1">Phone</strong>
                            <span><a href="tel:+923026144056">+92302-6144056</a></span>
                        </li>
                        <li className="mb-3">
                            <strong className="d-block mb-1">Email</strong>
                            <span><a href="mailto:moteeb83@gmail.com">moteeb83@gmail.com</a></span>
                        </li>
                    </ul>
                </div>
                <div className="col-md-4 d-none d-md-block">
                    <h3>More Info</h3>
                    <p>Hire me on PeoplePerHour for quality and reliable services.</p>
                    <div id="pph-hireme"></div>
                </div>
            </div>

            </div>
        </div>

        <div className='container-wrap'>            
            <nav className="navbar navbar-light custom-navbar">
                <div className="container">
                <NavLink className="navbar-brand" to="/">Moteeb Asad.</NavLink>
                {/* <a href="#" className="burger" data-bs-toggle="collapse" data-bs-target="#main-navbar">
                    <span></span>
                </a> */}
                <a
                href="#"
                className={`burger highlight-button  ${isActive ? "active" : ""}`}
                onClick={handleClick}
                data-bs-toggle="collapse"
                data-bs-target="#main-navbar"
                >
                <span></span>
                </a>
                </div>
            </nav>
        </div>
    </>
  )
}

export default Header