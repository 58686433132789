import React from 'react'

function CambridgeSpark() {
  return (
    <section className="section">
        <div className="container">
            <div className="row mb-4 align-items-center">
            <div className="col-md-6" data-aos="fade-up">
                <h2>Cambridge Spark Site</h2>
            </div>
            </div>
        </div>

        <div className="site-section pb-0">
            <div className="container">
            <div className="row align-items-stretch">
                <div className="col-md-8" data-aos="fade-up">
                <img src={require("../../assets/img/cambridge-spark-detail.webp")}  alt="Image" className="img-fluid" />
                </div>
                <div className="col-md-3 ml-auto" data-aos="fade-up" data-aos-delay="100">
                <div className="sticky-content">
                    <h4 className="h4 mb-3"><strong>DETAILS:</strong></h4>
                    <div className="mb-5">
                        <p>Implemented the client's Figma design concepts into a comprehensive website using HubSpot CMS. This process involved the development of custom modules for every page, utilizing the expressive features of the HubL template language. </p>
                    </div>
                    <h4 className="h4 mb-3"><strong>TOOLSET INCLUDED:</strong></h4>
                    <ul className="list-unstyled list-line mb-5">
                        <li>Hubspot's HubL template language</li>
                        <li>HTML5/CSS3/Bootstrap v5.3.0</li>
                        <li>Javascript</li>
                        <li>Lottie Animation</li>
                    </ul>
                    <p><a href="https://www.cambridgespark.com/" target="_blank" className="readmore">Visit Website</a></p>
                </div>
                </div>
            </div>
            </div>
        </div>
    </section>
  )
}

export default CambridgeSpark