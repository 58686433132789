import React from 'react'

function WesternStockman() {
  return (
    <section className="section">
        <div className="container">
            <div className="row mb-4 align-items-center">
            <div className="col-md-6" data-aos="fade-up">
                <h2>Western Stockman Site</h2>
            </div>
            </div>
        </div>

        <div className="site-section pb-0">
            <div className="container">
            <div className="row align-items-stretch">
                <div className="col-md-8" data-aos="fade-up">
                <img src={require("../../assets/img/westernstockman-detail.webp")}  alt="Image" className="img-fluid" />
                </div>
                <div className="col-md-3 ml-auto" data-aos="fade-up" data-aos-delay="100">
                <div className="sticky-content">
                    <h4 className="h4 mb-3"><strong>DETAILS:</strong></h4>
                    <div className="mb-5">
                        <p>On Western Stockman site, I developed site pages using liquid, customizing various sections to meet the client's requirements. I integrated metafields for dynamic product information display and utilized the Liquid template language with JavaScript where necessary to achieve the desired results.</p>
                    </div>
                    <h4 className="h4 mb-3"><strong>TOOLSET INCLUDED:</strong></h4>
                    <ul className="list-unstyled list-line mb-5">
                        <li>Shopify's Liquid template language</li>
                        <li>Swiper Slider</li>
                        <li>HTML5/CSS3</li>
                        <li>JavaScript</li>
                    </ul>
                    <p><a href="https://westernstockman.ca/" target="_blank" className="readmore">Visit Website</a></p>
                </div>
                </div>
            </div>
            </div>
        </div>
    </section>
  )
}

export default WesternStockman