import React from 'react'

function NorteCaca() {
  return (
    
    <section className="section">
        <div className="container">
            <div className="row mb-4 align-items-center">
            <div className="col-md-6" data-aos="fade-up">
                <h2>Norte Caca</h2>
            </div>
            </div>
        </div>

        <div className="site-section pb-0">
            <div className="container">
            <div className="row align-items-stretch">
                <div className="col-md-8" data-aos="fade-up">
                <img src={require("../../assets/img/nortecaca-detail.webp")}  alt="Image" className="img-fluid" />
                </div>
                <div className="col-md-3 ml-auto" data-aos="fade-up" data-aos-delay="100">
                <div className="sticky-content">
                    <h4 className="h4 mb-3"><strong>DETAILS:</strong></h4>
                    <div className="mb-5">
                        <p>This is the frontend of a site I converted to React.js from a design provided by the client in Figma. I created reusable components for the React.js app based on the design.</p>
                    </div>
                    <h4 className="h4 mb-3"><strong>TOOLSET INCLUDED:</strong></h4>
                    <ul className="list-unstyled list-line mb-5">
                        <li>React Components</li>
                        <li>React DayPicker</li>
                        <li>Bootstrap</li>
                    </ul>
                    <p><a href="https://norte-caca.netlify.app/" target="_blank" className="readmore">Visit Website</a></p>
                </div>
                </div>
            </div>
            </div>
        </div>
    </section>

  )
}

export default NorteCaca