import React from 'react'

function Shopsilkie() {
  return (
    <section className="section">
        <div className="container">
            <div className="row mb-4 align-items-center">
            <div className="col-md-6" data-aos="fade-up">
                <h2>Shopsilkie Site</h2>
            </div>
            </div>
        </div>

        <div className="site-section pb-0">
            <div className="container">
            <div className="row align-items-stretch">
                <div className="col-md-8" data-aos="fade-up">
                <img src={require("../../assets/img/shopsilkie-detail.webp")}  alt="Image" className="img-fluid" />
                </div>
                <div className="col-md-3 ml-auto" data-aos="fade-up" data-aos-delay="100">
                <div className="sticky-content">
                    <h4 className="h4 mb-3"><strong>DETAILS:</strong></h4>
                    <div className="mb-5">
                        <p>For the client website Shopsilkie, I developed site pages using the PageFly builder, customizing various sections to meet the client's requirements. I integrated metafields for dynamic product information display and utilized the Liquid template language with JavaScript where necessary to achieve the desired results.</p>
                    </div>
                    <h4 className="h4 mb-3"><strong>TOOLSET INCLUDED:</strong></h4>
                    <ul className="list-unstyled list-line mb-5">
                        <li>Shopify's Liquid template language</li>
                        <li>PageFly Builder</li>
                        <li>Section Schema</li>
                        <li>HTML5/CSS3</li>
                        <li>Javascript</li>
                    </ul>
                    <p><a href="https://www.shopsilkie.com/" target="_blank" className="readmore">Visit Website</a></p>
                </div>
                </div>
            </div>
            </div>
        </div>
    </section>
  )
}

export default Shopsilkie