import React from 'react'
import { NavLink } from 'react-router-dom'


function Work() {

  return (
   <>
        <div id="portfolio-grid" className="row no-gutter">
            <div className="item hubspot col-sm-6 col-md-4 col-lg-4 mb-4">
                <NavLink className="item-wrap fancybox" to="/cambridge-spark">
                <div className="work-info">
                    <h3>Cambridge Spark</h3>
                    <span>Web</span>
                </div>
                <img className="img-fluid" src={require("../assets/img/cambridge-spark-new.webp")} />
                </NavLink>
            </div>
            <div className="item reactjs col-sm-6 col-md-4 col-lg-4 mb-4">
                <NavLink className="item-wrap fancybox" to="/redux-movies-db">
                <div className="work-info">
                    <h3>Redux Movies DB</h3>
                    <span>Web</span>
                </div>
                <img className="img-fluid" src={require("../assets/img/redux-movies-db.webp")} />
                </NavLink>
            </div>
            <div className="item hubspot col-sm-6 col-md-4 col-lg-4 mb-4">
                <NavLink className="item-wrap fancybox" to="/harold-leidner">
                <div className="work-info">
                    <h3>Harold Leidner</h3>
                    <span>Web</span>
                </div>
                <img className="img-fluid" src={require("../assets/img/harold-leidner-new.webp")} />
                </NavLink>
            </div>
            <div className="item hubspot col-sm-6 col-md-4 col-lg-4 mb-4">
                <NavLink className="item-wrap fancybox" to="/smart-business-concepts">
                <div className="work-info">
                    <h3>Smart Business Concepts</h3>
                    <span>Web</span>
                </div>
                <img className="img-fluid" src={require("../assets/img/smart-business-concept-new.webp")} />
                </NavLink>
            </div>
            <div className="item wordpress col-sm-6 col-md-4 col-lg-4 mb-4">
                <NavLink className="item-wrap fancybox" to="/upac-kw">
                <div className="work-info">
                    <h3>UPAC Site</h3>
                    <span>Web</span>
                </div>
                <img className="img-fluid" src={require("../assets/img/upac-new.webp")} />
                </NavLink>
            </div>
            <div className="item bootstrap col-sm-6 col-md-4 col-lg-4 mb-4">
                <NavLink className="item-wrap fancybox" to="/soyouneed-therapy">
                <div className="work-info">
                    <h3>SoYouNeed Therapy</h3>
                    <span>Web</span>
                </div>
                <img className="img-fluid" src={require("../assets/img/soyouneed-therapy.webp")}/>
                </NavLink>
            </div>
            <div className="item bootstrap col-sm-6 col-md-4 col-lg-4 mb-4">
                <NavLink className="item-wrap fancybox" to="/gaze-therapy">
                <div className="work-info">
                    <h3>Gaze Therapy</h3>
                    <span>Web</span>
                </div>
                <img className="img-fluid" src={require("../assets/img/gaze-therapy-new.webp")}/>
                </NavLink>
            </div>
            <div className="item hubspot col-sm-6 col-md-4 col-lg-4 mb-4">
                <NavLink className="item-wrap fancybox" to="/cre-analyst">
                <div className="work-info">
                    <h3>Cre Analyst</h3>
                    <span>Web</span>
                </div>
                <img className="img-fluid" src={require("../assets/img/cre-analyst-new.webp")} />
                </NavLink>
            </div>
            <div className="item hubspot col-sm-6 col-md-4 col-lg-4 mb-4">
                <NavLink className="item-wrap fancybox" to="/quote-searcher">
                <div className="work-info">
                    <h3>Quote Searcher</h3>
                    <span>Web</span>
                </div>
                <img className="img-fluid" src={require("../assets/img/quote-searcher-new.webp")} />
                </NavLink>
            </div>
            <div className="item wordpress col-sm-6 col-md-4 col-lg-4 mb-4">
                <NavLink className="item-wrap fancybox" to="/stone-world">
                <div className="work-info">
                    <h3>Stone World</h3>
                    <span>Web</span>
                </div>
                <img className="img-fluid" src={require("../assets/img/stone-world-new.webp")} />
                </NavLink>
            </div>
            <div className="item wordpress col-sm-6 col-md-4 col-lg-4 mb-4">
                <NavLink className="item-wrap fancybox" to="/strength-camp">
                <div className="work-info">
                    <h3>Strength Camp</h3>
                    <span>Web</span>
                </div>
                <img className="img-fluid" src={require("../assets/img/strengthcamp-new.webp")} />
                </NavLink>
            </div>
            <div className="item wordpress col-sm-6 col-md-4 col-lg-4 mb-4">
                <NavLink className="item-wrap fancybox" to="/twin-cities-tandems">
                <div className="work-info">
                    <h3>Twin Cities Tandems</h3>
                    <span>Web</span>
                </div>
                <img className="img-fluid" src={require("../assets/img/twincitiestandems-new.webp")} />
                </NavLink>
            </div>
            <div className="item reactjs col-sm-6 col-md-4 col-lg-4 mb-4">
                <NavLink className="item-wrap fancybox" to="/norte-caca">
                <div className="work-info">
                    <h3>Norte Caca</h3>
                    <span>Web</span>
                </div>
                <img className="img-fluid" src={require("../assets/img/norte-caca.webp")} />
                </NavLink>
            </div>
            <div className="item reactjs col-sm-6 col-md-4 col-lg-4 mb-4">
                <NavLink className="item-wrap fancybox" to="/react-crud">
                <div className="work-info">
                    <h3>React CRUD</h3>
                    <span>Web</span>
                </div>
                <img className="img-fluid" src={require("../assets/img/react-crud.webp")} />
                </NavLink>
            </div>
            <div className="item reactjs col-sm-6 col-md-4 col-lg-4 mb-4">
                <NavLink className="item-wrap fancybox" to="/headless-cms-with-shopify-api-react-context">
                <div className="work-info">
                    <h3>Headless CMS (Shopify | ReactJS)</h3>
                    <span>Web</span>
                </div>
                <img className="img-fluid" src={require("../assets/img/headless-reactjs-shop-new.webp")} />
                </NavLink>
            </div>
            <div className="item bootstrap col-sm-6 col-md-4 col-lg-4 mb-4">
                <NavLink className="item-wrap fancybox" to="/neau-water-landing-page">
                <div className="work-info">
                    <h3>Neau Water Landing Page</h3>
                    <span>Web</span>
                </div>
                <img className="img-fluid" src={require("../assets/img/neau-water-new.webp")} />
                </NavLink>
            </div>
            <div className="item shopify col-sm-6 col-md-4 col-lg-4 mb-4">
                <NavLink className="item-wrap fancybox" to="/shopsilkie">
                <div className="work-info">
                    <h3>Shopsilkie</h3>
                    <span>Web</span>
                </div>
                <img className="img-fluid" src={require("../assets/img/shopsilkie-new.webp")} />
                </NavLink>
            </div>
            <div className="item shopify col-sm-6 col-md-4 col-lg-4 mb-4">
                <NavLink className="item-wrap fancybox" to="/rowland-emergency">
                <div className="work-info">
                    <h3>Rowland Emergency</h3>
                    <span>Web</span>
                </div>
                <img className="img-fluid" src={require("../assets/img/rowlandemergency-new.webp")} />
                </NavLink>
            </div>
            <div className="item shopify col-sm-6 col-md-4 col-lg-4 mb-4">
                <NavLink className="item-wrap fancybox" to="/nutribeauti">
                <div className="work-info">
                    <h3>Nutribeauti</h3>
                    <span>Web</span>
                </div>
                <img className="img-fluid" src={require("../assets/img/nutribeauti-new.webp")} />
                </NavLink>
            </div>
            <div className="item shopify col-sm-6 col-md-4 col-lg-4 mb-4">
                <NavLink className="item-wrap fancybox" to="/western-stockman">
                <div className="work-info">
                    <h3>Western Stockman</h3>
                    <span>Web</span>
                </div>
                <img className="img-fluid" src={require("../assets/img/westernstockman-new.webp")} />
                </NavLink>
            </div>
            <div className="item shopify col-sm-6 col-md-4 col-lg-4 mb-4">
                <NavLink className="item-wrap fancybox" to="/jordan-luca">
                <div className="work-info">
                    <h3>JordanLuca</h3>
                    <span>Web</span>
                </div>
                <img className="img-fluid" src={require("../assets/img/jordanluca-new.webp")} />
                </NavLink>
            </div>
            {/* <div className="item shopify col-sm-6 col-md-4 col-lg-4 mb-4">
                <NavLink className="item-wrap fancybox" to="/flora-cbd">
                <div className="work-info">
                    <h3>Flora CBD</h3>
                    <span>Web</span>
                </div>
                <img className="img-fluid" src={require("../assets/img/flora-cbd-new.webp")} />
                </NavLink>
            </div> */}
            <div className="item shopify col-sm-6 col-md-4 col-lg-4 mb-4">
                <NavLink className="item-wrap fancybox" to="/sneaker-care">
                <div className="work-info">
                    <h3>X Sneaker Care</h3>
                    <span>Web</span>
                </div>
                <img className="img-fluid" src={require("../assets/img/xsneakers-new.webp")} />
                </NavLink>
            </div>
        </div>
   </>
  )
}

export default Work