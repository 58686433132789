import React from 'react'

function JordanLuca() {
  return (
    <section className="section">
        <div className="container">
            <div className="row mb-4 align-items-center">
            <div className="col-md-6" data-aos="fade-up">
                <h2>JordanLuca Site</h2>
            </div>
            </div>
        </div>

        <div className="site-section pb-0">
            <div className="container">
            <div className="row align-items-stretch">
                <div className="col-md-8" data-aos="fade-up">
                <img src={require("../../assets/img/jordanluca-detail.webp")}  alt="Image" className="img-fluid" />
                </div>
                <div className="col-md-3 ml-auto" data-aos="fade-up" data-aos-delay="100">
                <div className="sticky-content">
                    <h4 className="h4 mb-3"><strong>DETAILS:</strong></h4>
                    <div className="mb-5">
                        <p>On the JordanLuca site, I received a client-provided design to restructure the entire homepage. I developed all homepage sections using Liquid, including the header and footer, on the existing theme of the Jordan Luca site. Additionally, I provided options in the theme editor to allow the admin to dynamically manage content for products and collections.</p>
                    </div>
                    <h4 className="h4 mb-3"><strong>TOOLSET INCLUDED:</strong></h4>
                    <ul className="list-unstyled list-line mb-5">
                        <li>Shopify's Liquid template language</li>
                        <li>HTML5/CSS3</li>
                        <li>JavaScript</li>
                    </ul>
                    <p><a href="https://www.jordanluca.com/" target="_blank" className="readmore">Visit Website</a></p>
                </div>
                </div>
            </div>
            </div>
        </div>
    </section>
  )
}

export default JordanLuca