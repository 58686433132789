import React from 'react'

function ShopifyAPIReact() {
  return (
    <section className="section">
    <div className="container">
        <div className="row mb-4 align-items-center">
        <div className="col-md-6" data-aos="fade-up">
            <h2>Headless CMS ( Shopify | React )</h2>
        </div>
        </div>
    </div>

    <div className="site-section pb-0">
        <div className="container">
        <div className="row align-items-stretch">
            <div className="col-md-8" data-aos="fade-up">
            <img src={require("../../assets/img/headless-reactjs-shop.webp")}  alt="Image" className="img-fluid" />
            </div>
            <div className="col-md-3 ml-auto" data-aos="fade-up" data-aos-delay="100">
            <div className="sticky-content">
                <h4 className="h4 mb-3"><strong>DETAILS:</strong></h4>
                <div className="mb-5">
                    <p>This is a side project where I used ReactJS and the Shopify API to fetch products. I employed ReactJS context for state management and utilized Bootstrap for the frontend. Pagination was added to the products and a cart page with functionalities was created. 
                       The "Proceed to Checkout" button redirects the user to the Shopify checkout page.</p>
                </div>
                <h4 className="h4 mb-3"><strong>TOOLSET INCLUDED:</strong></h4>
                <ul className="list-unstyled list-line mb-5">
                    <li>Shopify APIs</li>
                    <li>React Context For State Management</li>
                    <li>Bootstrap</li>
                </ul>
                <p><a href="https://headless-reactjs-shop.netlify.app/" target="_blank" className="readmore">Visit Website</a></p>
            </div>
            </div>
        </div>
        </div>
    </div>
</section>
  )
}

export default ShopifyAPIReact