import React from 'react'

function ReduxMoviesDB() {
  return (
    <section className="section">
        <div className="container">
            <div className="row mb-4 align-items-center">
            <div className="col-md-6" data-aos="fade-up">
                <h2>Redux Movies DB</h2>
            </div>
            </div>
        </div>

        <div className="site-section pb-0">
            <div className="container">
            <div className="row align-items-stretch">
                <div className="col-md-8" data-aos="fade-up">
                <img src={require("../../assets/img/redux-movies-db.webp")}  alt="Image" className="img-fluid" />
                </div>
                <div className="col-md-3 ml-auto" data-aos="fade-up" data-aos-delay="100">
                <div className="sticky-content">
                    <h4 className="h4 mb-3"><strong>DETAILS:</strong></h4>
                    <div className="mb-5">
                        <p>Developed a React application featuring trending, popular, in-theatre, and upcoming movies. Implemented a user-friendly interface with a search bar for title-based movie searches. Incorporated sorting options for users to organize movies based on different criteria, enhancing user experience and customization. Leveraged Redux Toolkit and Redux Persist for efficient state management, ensuring seamless data flow and persistence across the application.</p>
                    </div>
                    <h4 className="h4 mb-3"><strong>TOOLSET INCLUDED:</strong></h4>
                    <ul className="list-unstyled list-line mb-5">
                        <li>ReactJS, React Router DOM</li>
                        <li>Chakra UI</li>
                        <li>Redux Toolkit, Redux Persist</li>
                        <li>The Movie Database (TMBD) APIs</li>
                    </ul>
                    <p><a href="https://redux-moviesdb.netlify.app/" target="_blank" className="readmore">Visit Website</a></p>
                </div>
                </div>
            </div>
            </div>
        </div>
    </section>
  )
}

export default ReduxMoviesDB