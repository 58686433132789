import React from 'react'

function NeauWater() {
  return (
    <section className="section">
    <div className="container">
        <div className="row mb-4 align-items-center">
        <div className="col-md-6" data-aos="fade-up">
            <h2>Neau Water Landing Page</h2>
        </div>
        </div>
    </div>

    <div className="site-section pb-0">
        <div className="container">
        <div className="row align-items-stretch">
            <div className="col-md-8" data-aos="fade-up">
            <img src={require("../../assets/img/neau-water-detail.webp")}  alt="Image" className="img-fluid" />
            </div>
            <div className="col-md-3 ml-auto" data-aos="fade-up" data-aos-delay="100">
            <div className="sticky-content">
                <h4 className="h4 mb-3"><strong>DETAILS:</strong></h4>
                <div className="mb-5">
                    <p>This is a demo landing page I created for a client based on the design provided. 
                       I used Bootstrap v5, Slick Carousel, and the jQuery Fullpage plugin to build the landing page.</p>
                </div>
                <h4 className="h4 mb-3"><strong>TOOLSET INCLUDED:</strong></h4>
                <ul className="list-unstyled list-line mb-5">
                    <li>HTML5/CSS3</li>
                    <li>Bootstrap v5</li>
                    <li>Slick Carousel</li>
                    <li>Jquery Full Page Plugin</li>
                </ul>
                <p><a href="https://neau-landing.netlify.app/" target="_blank" className="readmore">Visit Website</a></p>
            </div>
            </div>
        </div>
        </div>
    </div>
</section>
  )
}

export default NeauWater