import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';

import './App.css';
import 'aos/dist/aos.css';
import '../src/assets/js/main.js';

import HaroldLeidner from './components/work-single/HaroldLeidner';
import SBC from './components/work-single/SBC';
import QuoteSearcher from './components/work-single/QuoteSearcher';
import CreAnalyst from './components/work-single/CreAnalyst';
import Nutribeauti from './components/work-single/Nutribeauti';
import FloraCBD from './components/work-single/FloraCBD';
import SneakerCare from './components/work-single/SneakerCare';
import TwinCitiesTandems from './components/work-single/TwinCitiesTandems';
import StoneWorld from './components/work-single/StoneWorld';
import StrengthCamp from './components/work-single/StrengthCamp';
import ShopifyAPIReact from './components/work-single/ShopifyAPIReact';
import GazeTherapy from './components/work-single/GazeTherapy';
import NeauWater from './components/work-single/NeauWater';
import CambridgeSpark from './components/work-single/CambridgeSpark';
import NorteCaca from './components/work-single/NorteCaca.js';
import SoYouNeedTherapy from './components/work-single/SoYouNeedTherapy.js';
import ReduxMoviesDB from './components/work-single/ReduxMoviesDB.js';
import ReactCrud from './components/work-single/ReactCrud.js';
import UPAC from './components/work-single/UPAC.js';
import Shopsilkie from './components/work-single/Shopsilkie.js';
import RowlandEmergency from './components/work-single/RowlandEmergency.js';
import WesternStockman from './components/work-single/WesternStockman.js';
import JordanLuca from './components/work-single/JordanLuca.js';


function App() {

  return (
    <div className="App">
      <Router>
          <Header />
          <Routes>
            <Route exact path="/" element={<Home />}/>
            <Route exact path="/cambridge-spark" element={<CambridgeSpark />}/>
            <Route exact path="/harold-leidner" element={<HaroldLeidner />}/>
            <Route exact path="/smart-business-concepts" element={<SBC />}/>
            <Route exact path="/quote-searcher" element={<QuoteSearcher />}/>
            <Route exact path="/cre-analyst" element={<CreAnalyst />}/>
            <Route exact path="/redux-movies-db" element={<ReduxMoviesDB />}/>
            <Route exact path="/nutribeauti" element={<Nutribeauti />}/>
            <Route exact path="/flora-cbd" element={<FloraCBD />}/>
            <Route exact path="/sneaker-care" element={<SneakerCare />}/>
            <Route exact path="/twin-cities-tandems" element={<TwinCitiesTandems />}/>
            <Route exact path="/stone-world" element={<StoneWorld />}/>
            <Route exact path="/strength-camp" element={<StrengthCamp />}/>
            <Route exact path="/headless-cms-with-shopify-api-react-context" element={<ShopifyAPIReact />}/>
            <Route exact path="/norte-caca" element={<NorteCaca />}/>
            <Route exact path="/react-crud" element={<ReactCrud />}/>
            <Route exact path="/gaze-therapy" element={<GazeTherapy />}/>
            <Route exact path="/soyouneed-therapy" element={<SoYouNeedTherapy />}/>
            <Route exact path="/neau-water-landing-page" element={<NeauWater />}/>
            <Route exact path="/upac-kw" element={<UPAC />}/>
            <Route exact path="/shopsilkie" element={<Shopsilkie />}/>
            <Route exact path="/rowland-emergency" element={<RowlandEmergency />}/>
            <Route exact path="/western-stockman" element={<WesternStockman />}/>
            <Route exact path="/jordan-luca" element={<JordanLuca />}/>
          </Routes>
          <Footer/>
      </Router>
    </div>
  );
}

export default App;
