import React from 'react'

function UPAC() {
  return (
    <section className="section">
        <div className="container">
            <div className="row mb-4 align-items-center">
            <div className="col-md-6" data-aos="fade-up">
                <h2>UPAC Site</h2>
            </div>
            </div>
        </div>

        <div className="site-section pb-0">
            <div className="container">
            <div className="row align-items-stretch">
                <div className="col-md-8" data-aos="fade-up">
                <img src={require("../../assets/img/upac-detail.webp")}  alt="Image" className="img-fluid" />
                </div>
                <div className="col-md-3 ml-auto" data-aos="fade-up" data-aos-delay="100">
                <div className="sticky-content">
                    <h4 className="h4 mb-3"><strong>DETAILS:</strong></h4>
                    <div className="mb-5">
                        <p>Developed several custom pages for the UPAC site based on the design provided by the client. I also utilized Flex Slider to create a custom banner section with a sliding effect. The entire site was built on the WordPress CMS using PHP & Advance Custom Fields.</p>
                    </div>
                    <h4 className="h4 mb-3"><strong>TOOLSET INCLUDED:</strong></h4>
                    <ul className="list-unstyled list-line mb-5">
                        <li>Custom Wordpress Tempaltes</li>
                        <li>PHP/ACF</li>
                        <li>Flex Slider</li>
                        <li>HTML5/CSS3</li>
                        <li>Javascript</li>
                    </ul>
                    <p><a href="https://www.upac.com.kw/" target="_blank" className="readmore">Visit Website</a></p>
                </div>
                </div>
            </div>
            </div>
        </div>
    </section>
  )
}

export default UPAC