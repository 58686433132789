import React from 'react'

function StrengthCamp() {
  return (
    <section className="section">
        <div className="container">
            <div className="row mb-4 align-items-center">
            <div className="col-md-6" data-aos="fade-up">
                <h2>Strength Camp Site</h2>
            </div>
            </div>
        </div>

        <div className="site-section pb-0">
            <div className="container">
            <div className="row align-items-stretch">
                <div className="col-md-8" data-aos="fade-up">
                <img src={require("../../assets/img/strengthcamp-detail.webp")}  alt="Image" className="img-fluid" />
                </div>
                <div className="col-md-3 ml-auto" data-aos="fade-up" data-aos-delay="100">
                <div className="sticky-content">
                    <h4 className="h4 mb-3"><strong>DETAILS:</strong></h4>
                    <div className="mb-5">
                        <p>For the Strength Camp site, I focused on the frontend development. The client supplied Figma designs, which I converted into a WordPress CMS using the Oxygen builder per the client's request. 
                           Another developer set up WooCommerce on the shop page.</p>
                    </div>
                    <h4 className="h4 mb-3"><strong>TOOLSET INCLUDED:</strong></h4>
                    <ul className="list-unstyled list-line mb-5">
                        <li>Oxygen Page Builder</li>
                        <li>Custom Templates</li>
                        <li>Woocommerce</li>
                        <li>HTML5, CSS3 & Javascript</li>
                    </ul>
                    <p><a href="https://strengthcampbc.com/" target="_blank" className="readmore">Visit Website</a></p>
                </div>
                </div>
            </div>
            </div>
        </div>
    </section>
  )
}

export default StrengthCamp