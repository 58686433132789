import React,{useEffect,useState} from 'react'
import AOS from 'aos';
import Isotope from "isotope-layout";
import Work from '../components/Work'
import Services from '../components/Services'
import { Select, On } from '../assets/js/custom.js'

function Home() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
      let portfolioContainer = Select('#portfolio-grid');
      if (portfolioContainer) {
        let portfolioIsotope = new Isotope(portfolioContainer, {
          itemSelector: '.item'
        });

        let portfolioFilters = Select('#filters a', true);

        On('click', '#filters a', function (e) {
          e.preventDefault();
          portfolioFilters.forEach(function (el) {
            el.classList.remove('active');
          });
          this.classList.add('active');

          portfolioIsotope.arrange({
            filter: this.getAttribute('data-filter')
          });
          portfolioIsotope.on('arrangeComplete', function () {
            AOS.refresh();
          });
        }, true);

        return function cleanup() {
          portfolioIsotope.destroy();
        }
      }
    }, 500);
  }, [isLoading]);
  

  return (
    <>
      <section className="section site-portfolio">
            <div className="container">
                <div className="row mb-5 align-items-center">
                    <div className="col-md-12 col-lg-6 mb-4 mb-lg-0" >
                        <h2>Recent Work</h2>
                    </div>
                    <div className="col-md-12 col-lg-6 text-start text-lg-end" >
                        <div id="filters" className="filters">
                        <a href="#" data-filter="*" className="active">All</a>
                        <a href="#" data-filter=".reactjs">ReactJS</a>
                        <a href="#" data-filter=".shopify">Shopify</a>
                        <a href="#" data-filter=".hubspot">HubSpot</a>
                        <a href="#" data-filter=".wordpress">WordPress</a>        
                        <a href="#" data-filter=".bootstrap">Bootstrap</a>
                        </div>
                    </div>
                </div>
                {isLoading ? (
                  <div className='loading-dots-wrap'>
                    Loading projects
                    <div className="loading-dots">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                ) : (
                  <Work/>
                )}
                
            </div>
        </section>
        <Services />
    </>
  )
}

export default Home