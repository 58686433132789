import React from 'react'

function Footer() {
  return (
    <>
        <footer className="footer" role="contentinfo">
            <div className="container">
            <div className="row">
                <div className="col-sm-6 copyright-col">
                  <p>&copy; Copyright 2024. All Rights Reserved</p>
                </div>
                <div className="col-sm-6 social text-md-end">
                  <a href="https://www.peopleperhour.com/freelancer/technology-programming/moteeb-asad-reactjs-shopify-hubspot-wordpress-zjzmjnx" target="_blank">
                    <img src={require("../assets/img/pph.webp")}/>
                  </a>
                  <a href="https://www.linkedin.com/in/moteeb-asad/" target="_blank">
                    <span className="bi bi-linkedin"></span>
                  </a>
                </div>
            </div>
            </div>
        </footer>

        <a href="#" className="back-to-top d-flex align-items-center justify-content-center">
          <i className="bi bi-arrow-up-short"></i>
        </a>
    </>
  )
}

export default Footer