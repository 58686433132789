/**
   * Easy selector helper function
   */
const Select = (el, all = false) => {
    el = el.trim()
    if (all) {
    return [...document.querySelectorAll(el)]
    } else {
    return document.querySelector(el)
    }
}

/**
 * Easy event listener function
 */
const On = (type, el, listener, all = false) => {
    let selectEl = Select(el, all)
    if (selectEl) {
    if (all) {
        selectEl.forEach(e => e.addEventListener(type, listener))
    } else {
        selectEl.addEventListener(type, listener)
    }
    }
}


export { Select, On };