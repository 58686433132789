import React from 'react'

function ReactCrud() {
  return (
    <section className="section">
        <div className="container">
            <div className="row mb-4 align-items-center">
            <div className="col-md-6" data-aos="fade-up">
                <h2>React CRUD</h2>
            </div>
            </div>
        </div>

        <div className="site-section pb-0">
            <div className="container">
            <div className="row align-items-stretch">
                <div className="col-md-8" data-aos="fade-up">
                <img src={require("../../assets/img/react-crud.webp")}  alt="Image" className="img-fluid" />
                </div>
                <div className="col-md-3 ml-auto" data-aos="fade-up" data-aos-delay="100">
                <div className="sticky-content">
                    <h4 className="h4 mb-3"><strong>DETAILS:</strong></h4>
                    <div className="mb-5">
                        <p>Developed Reactjs CRUD application that allows users to perform operations such as adding, viewing, deleting, and editing records. The application includes form validation using Formik and utilizes the Context API for state management and data flow. Furthermore, it integrates browser IndexedDB to store records data, which is then presented using Chakra UI tables. Additionally, the application features data filtration by most upvoted and most recent records, as well as a search bar functionality for specific data retrieval from the table.</p>
                    </div>
                    <h4 className="h4 mb-3"><strong>TOOLSET INCLUDED:</strong></h4>
                    <ul className="list-unstyled list-line mb-5">
                        <li>ReactJS, React Router DOM</li>
                        <li>Chakra UI</li>
                        <li>React Context</li>
                        <li>React Formik, IndexDB For Storage</li>
                    </ul>
                    <p><a href="https://react-crud-92.netlify.app/" target="_blank" className="readmore">Visit Website</a></p>
                </div>
                </div>
            </div>
            </div>
        </div>
    </section>
  )
}

export default ReactCrud