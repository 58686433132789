import React from 'react'

function Nutribeauti() {
  return (
    <section className="section">
        <div className="container">
            <div className="row mb-4 align-items-center">
            <div className="col-md-6" data-aos="fade-up">
                <h2>Nutribeauti Site</h2>
            </div>
            </div>
        </div>

        <div className="site-section pb-0">
            <div className="container">
            <div className="row align-items-stretch">
                <div className="col-md-8" data-aos="fade-up">
                <img src={require("../../assets/img/nutribeauti-detail.webp")}  alt="Image" className="img-fluid" />
                </div>
                <div className="col-md-3 ml-auto" data-aos="fade-up" data-aos-delay="100">
                <div className="sticky-content">
                    <h4 className="h4 mb-3">Details:</h4>
                    <div className="mb-5">
                        <p>Nutribeauti is a client website where I primarily handle maintenance tasks. I have customized various sections to meet the client's needs and integrated metafields for dynamic product information display. 
                           I also set up discount promotions on the site as requested by the client. </p>
                    </div>
                    <h4 className="h4 mb-3">My toolset included:</h4>
                    <ul className="list-unstyled list-line mb-5">
                        <li>Shopify's Liquid template language</li>
                        <li>Section Schema</li>
                        <li>HTML5/CSS3</li>
                        <li>Javascript</li>
                    </ul>
                    <p><a href="https://nutribeauti.com/" target="_blank" className="readmore">Visit Website</a></p>
                </div>
                </div>
            </div>
            </div>
        </div>
    </section>
  )
}

export default Nutribeauti